import * as Token from "@/lib/eth/Token";
import { getNetwork, networkList } from "@/assets/lib/networks";
import { getAddress } from "@blockwell/chain-client";
import { getPrices } from "@/lib/prices";
import { addressEqual } from "@/lib/eth/Contract";
import { sort } from "rambdax";
export const ethList = [
    {
        name: "Ethereum",
        network: 1,
        sendLink: "/f3wrb1",
        symbol: "ETH",
    },
    {
        name: "Ethereum",
        network: 4,
        sendLink: "/mylfts",
        symbol: "ETH",
        standard: true,
    },
    {
        name: "Ethereum",
        network: 5,
        sendLink: "/d87rsk",
        symbol: "ETH",
    },
    {
        name: "Ethereum",
        network: 49777,
        sendLink: "/5qf42v",
        symbol: "ETH",
        standard: true,
    },
    {
        name: "BNB",
        network: 56,
        sendLink: "/o46dux",
        symbol: "BNB",
        standard: true,
    },
    {
        name: "Avalanche",
        network: 43114,
        sendLink: "/yo5jxt",
        symbol: "AVAX",
        standard: true,
    },
    // {
    //     name: "Ronin",
    //     network: 2020,
    //     sendLink: "/26xge7",
    //     symbol: "RON",
    //     standard: true,
    // },
].map((it) => {
    return {
        ...it,
        address: "0x0000000000000000000000000000000000000000",
        balance: null,
        decimals: 18,
    };
});
async function addPrices(input) {
    let prices = await getPrices(input.map((it) => it.address));
    return input.map((it) => {
        let token = { ...it };
        let price = prices[token.address.toLowerCase()];
        if (price && price.gt(0) && token.balance && token.balance.gt(0)) {
            token.price = price;
            token.usd = price.times(token.balance);
        }
        return token;
    });
}
async function balancesForNetwork(net, account, tokens, callback) {
    let balances;
    let availables;
    try {
        balances = await Token.getTokenBalances(net.chainId, tokens.map((it) => it.address), account);
    }
    catch (err) {
        console.error("caught balances", net.networkName, err);
        callback(net, err);
        return;
    }
    try {
        availables = await Token.getAvailableBalances(net.chainId, tokens.map((it) => it.address), account);
    }
    catch (err) {
        console.error("caught availables", err);
        availables = [];
    }
    let i = 0;
    for (let token of tokens) {
        let balance = balances[i];
        let available = availables[i];
        if (balance.gt(0)) {
            token.balance = balance.shiftedBy((token.decimals || 18) * -1);
        }
        else {
            token.balance = null;
        }
        if (available && available.lt(balance)) {
            token.available = available.shiftedBy((token.decimals || 18) * -1);
            token.locked = balance.minus(available).shiftedBy((token.decimals || 18) * -1);
        }
        ++i;
    }
    let filtered = tokens
        .filter((it) => {
        return ((it.address.toLowerCase() === "0xff255f8a7cf00d68a123a2553a7d0cdca63f61c3" &&
            it.network === 5) ||
            !it.standard ||
            !!it.balance);
    })
        .map((it) => addTokenProperties(it));
    if (net.chainId === 1) {
        try {
            filtered = await addPrices(filtered);
        }
        catch (err) {
            console.error("Error reading prices", err);
        }
    }
    callback(net, undefined, filtered);
}
function addTokenProperties(input) {
    let token = {
        ...input,
    };
    // Some have a specific sendLink already set
    if (!token.sendLink) {
        token.sendLink = `/app/wallet/send?net=${token.network}&contract=${token.address}`;
    }
    token.link = getNetwork(token.network).explorerToken(token.address);
    if (token.bwtype === 19) {
        token.happLink = `/core?contract=${token.address}&net=${token.network}`;
    }
    if (token.address.toLowerCase() === "0x80edc5ae6de1415b878d234b24568250a95cc9fb") {
        token.happLink = {
            link: "/2n8llj",
            label: "Unstake",
        };
    }
    if (token.address.toLowerCase() === "0x019b9b5f9fad299196ef02f4acb53f6e8b3bebb9") {
        token.happLink = {
            link: "/l0x5gp",
            label: "Stake",
        };
    }
    if (token.network === 1 &&
        token.address.toLowerCase() === "0xdef1da03061ddd2a5ef6c59220c135dec623116d") {
        token.happLink = {
            link: "/m5a1gf",
            label: "Stake",
        };
    }
    if (token.network === 49777 &&
        token.address.toLowerCase() === "0x9fedef2647a356f545c13b27f9e0e9d7f08c87e9") {
        token.happLink = {
            link: "/lrd5xn",
            label: "Unstake",
        };
    }
    if (token.network === 4 &&
        token.address.toLowerCase() === "0x3e21826b3791ffc8ea3d7430d25a2fad354ab53f") {
        token.happLink = {
            link: "/xe5j62",
            label: "Stake",
        };
    }
    if (token.network === 49111 &&
        token.address.toLowerCase() === "0x93a9112fed4e401329afde91fbd9644c62b073a4") {
        token.happLink = {
            link: "/yifrkw",
            label: "Unstake",
        };
    }
    if (token.network === 5 &&
        token.address.toLowerCase() === "0x58443a905f4c74f648262284defe8d6da2272095") {
        token.happLink = {
            link: "/suggestions?net=5&contract=0x58443a905f4c74f648262284defe8d6da2272095",
            label: "Voting",
        };
    }
    if (token.network === 4 &&
        token.address.toLowerCase() === "0xc16fbd9235e3ee9be0e7e5380ec11cdff1af99e8") {
        token.happLink = {
            link: "/2yubwb",
            label: "Stake",
        };
    }
    if (token.network === 49111 &&
        token.address.toLowerCase() === "0x000080f2b7e1f1411285e1b9a32c359f1d35e675") {
        token.happLink = {
            link: "/wi4aaa",
            label: "Unstake",
        };
    }
    if (token.network === 4 &&
        token.address.toLowerCase() === "0xc001da01867a567cce1b5de38e93a2c21d225266") {
        token.happLink = {
            link: "/7oku11",
            label: "Stake",
        };
    }
    if (token.network === 49113 &&
        token.address.toLowerCase() === "0xc001da01867a567cce1b5de38e93a2c21d225266") {
        token.happLink = {
            link: "/ukzyd4",
            label: "Unstake",
        };
    }
    if (token.address === "0x0" || token.address === "0x0000000000000000000000000000000000000000") {
        if (token.network === 56 || token.network === 97) {
            token.logo = require("@/assets/images/logos/bnb.png");
        }
        else if (token.network === 2020) {
            token.logo = require("@/assets/images/logos/ronin.png");
        }
        else if (token.network === 43114) {
            token.logo = require("@/assets/images/logos/avax.png");
        }
        else {
            token.logo = require("@/assets/images/ethereum.png");
        }
        token.ether = true;
    }
    else if (token.network === 5 &&
        token.address.toLowerCase() === "0x555555555130790a502c3c95e9c26633c10ae000") {
        token.logo = require("@/assets/images/tutor.png");
    }
    else if (token.address.toLowerCase() === "0xdef1da03061ddd2a5ef6c59220c135dec623116d" ||
        (token.network === 49777 &&
            token.address.toLowerCase() === "0x9fedef2647a356f545c13b27f9e0e9d7f08c87e9")) {
        token.logo = "https://s2.coinmarketcap.com/static/img/coins/64x64/8845.png";
    }
    else if (token.network === 1) {
        let checksum = getAddress(token.address);
        token.logo = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${checksum}/logo.png`;
    }
    return token;
}
export function getTokenBalances(tokenList, account, callback) {
    let parted = {};
    for (let net of networkList()) {
        parted[net.chainId] = [];
    }
    for (let token of tokenList) {
        if (!parted[token.network]) {
            parted[token.network] = [];
        }
        parted[token.network].push(token);
    }
    let chainIds = [];
    for (let [networkId, list] of Object.entries(parted)) {
        let net = getNetwork(networkId);
        if (net.chainId === 0 || net.chainId === 4) {
            console.error("Trying to load tokens for an unknown network: " + networkId);
            continue;
        }
        chainIds.push(net.chainId);
        // Detaching this network from the current call stack
        setTimeout(() => {
            if (list.length > 0) {
                balancesForNetwork(net, account, list, callback).catch((err) => {
                    console.error("getTokenBalances", err);
                    callback(net, err);
                });
            }
            else {
                callback(net, undefined, []);
            }
        }, 1);
    }
    return chainIds;
}
export function sortTokens(tokens) {
    let weight = (token) => {
        let weight = 0;
        if (token.network === 49111 &&
            addressEqual(token.address, "0xeeeeeeee8c82e57af492fe03dae4b3c26f199cc4")) {
            return -2000;
        }
        if (token.network === 1) {
            if (token.ether) {
                return -1000;
            }
            if (token.usd) {
                weight -= 2;
            }
            weight -= 1;
        }
        if (token.network === 4) {
            if (token.ether) {
                return -900;
            }
        }
        if (token.network === 5) {
            if (addressEqual(token.address, "0x555555555130790a502C3c95E9C26633c10Ae000")) {
                return -999;
            }
            if (token.ether) {
                return -901;
            }
        }
        if (token.happLink && typeof token.happLink !== "string") {
            weight -= 600;
        }
        else if (token.happLink) {
            weight -= 400;
        }
        else if (token.network > 49000) {
            weight -= 500;
        }
        return weight;
    };
    return sort((a, b) => {
        return weight(a) - weight(b);
    }, tokens);
}
