import { getNetwork } from "@/assets/lib/networks";
import { errorText } from "@/assets/lib/util";
import ProgressCircle from "@/components/ProgressCircle";
import { Chain } from "@blockwell/chain-client";
import ContractLoader from "@/views/happs/components/ContractLoader";
import BigNumber from "bignumber.js";
import { mapGetters, mapState } from "vuex";
export default {
    name: "AddToken",
    components: { ProgressCircle, ContractLoader },
    data() {
        return {
            addingLoading: false,
            networkOptions: [],
            adding: {
                address: "",
                network: null,
                name: "",
                symbol: "",
                decimals: null
            },
            balance: "",
            addingError: null
        };
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('user', ['api']),
        networkName() {
            if (this.adding.network) {
                return getNetwork(this.adding.network).networkName;
            }
            return "";
        }
    },
    watch: {
        adding: {
            deep: true,
            handler(val) {
                if (val.network) {
                    Chain.getTokenBalance(val.network, val.address, this.account)
                        .then(res => {
                        this.balance = new BigNumber(res).div(`1e${val.decimals || 0}`).toFormat();
                    });
                }
            }
        }
    },
    methods: {
        loadContract(contract) {
            this.addingError = null;
            this.adding.address = contract.address;
            this.adding.name = contract.name;
            this.adding.symbol = contract.parameters.symbol;
            this.adding.decimals = contract.parameters.decimals;
            this.adding.network = contract.network;
        },
        selectNetwork(data) {
            this.adding.name = data.name;
            this.adding.symbol = data.parameters.symbol;
            this.adding.decimals = data.parameters.decimals;
            this.adding.network = data.network;
            this.networkOptions = [];
        },
        submitToken() {
            this.addingLoading = true;
            let add = this.adding;
            this.api.addToken(add.network, add.address, add.name, add.symbol, add.decimals)
                .then(async () => {
                this.adding.network = null;
                this.$emit('added');
            })
                .catch(err => {
                console.error(err);
                this.addingError = 'There was an error adding the token: ' + errorText(err);
            })
                .finally(() => this.addingLoading = false);
        }
    }
};
