<template>
    <layout-default>
        <h3 id="scanner-title">Scan QR Code</h3>
        <p>Use your camera to scan a QR Code.</p>
        <div class="qr-scanner">
            <div v-if="!loadingError" class="video-container">
                <video muted playsinline autoplay ref="video" class="address-qr-video"></video>
                <div class="video-overlay">
                    <div class="shaded"></div>
                    <div class="shaded"></div>
                    <div class="shaded"></div>
                    <div class="shaded"></div>
                    <div class="finder" ref="finder"></div>
                    <div class="shaded"></div>
                    <div class="shaded"></div>
                    <div class="shaded"></div>
                    <div class="shaded"></div>
                </div>
            </div>
            <div v-else class="error">{{ loadingError }}</div>
            <div class="error">{{ error }}</div>
        </div>
    </layout-default>
</template>

<script>
import QrScanner from 'qr-scanner';
import ResizeObserver from "resize-observer-polyfill";

export default {
    name: "Scanner",
    data() {
        return {
            loadingError: null,
            error: null,
            scannedAddress: null,
            allowedHosts: [
                'qr.blockwell.ai', 'app.blockwell.ai'
            ]
        };
    },
    mounted() {
        (async () => {
            try {
                const video = this.$refs.video;
                video.setAttribute('autoplay', '');
                video.setAttribute('muted', '');
                video.setAttribute('playsinline', '');
                video.controls = false;

                this.qrScanner = new QrScanner(video, result => {
                    const url = new URL(result);

                    if (this.allowedHosts.includes(url.hostname.toLowerCase()) && url.pathname.length === 7) {
                        this.$router.push({
                            path: url.pathname,
                            query: url.searchParams
                        });
                    } else {
                        this.error = `That QR Code is not a valid Blockwell code.`;
                    }
                });
                this.qrScanner.setInversionMode('both');
                await this.qrScanner.start();
                this.loadingError = null;
            } catch (err) {
                if (navigator.userAgent.match(/(iPad|iPhone)/)) {
                    alert("Looks like you're on iOS. The QR Scanner only works in Safari at this time.");
                }
                this.loadingError = "There was a problem loading a camera for the scanner: " + (err.message || err);
            }
            $('html, body').animate({
                scrollTop: $('#scanner-title').offset().top
            }, 200);
        })()
            .catch(console.error);

        this.observer = new ResizeObserver(entries => {
            const {width, height} = entries[0].contentRect;
            const finder = this.$refs.finder;

            let size = Math.round(Math.min(width, height) * 0.66);

            finder.style.width = size + "px";
            finder.style.height = size + "px";
        });

        this.observer.observe(this.$refs.video);
    },
    methods: {
        showError(err) {
            console.error(err);
            $('#scanner').hide();
            $('.scanner-error').html(`
                    <p>Could not load a camera for the scanner.</p>
                `);

            if (navigator.userAgent.match(/(iPad|iPhone)/)) {
                alert("Looks like you're on iOS. The QR Scanner only works in Safari at this time.");
            }
        }
    },
    destroyed() {
        if (this.qrScanner) {
            this.qrScanner.destroy();
            this.qrScanner = null;
        }
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }
}

</script>

<style scoped>

</style>
