import { getNetwork } from "@/assets/lib/networks";
import AddToken from "@/components/AddToken.vue";
import AssetList from "@/components/AssetList.vue";
import Clipboard from "@/components/Clipboard.vue";
import Modal from "@/components/Modal.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import TransactionHistory from "@/components/TransactionHistory.vue";
import Heading from "@/layouts/Heading.vue";
import { randomBlockwellApp } from "@/lib/blockwell-apps";
import { ethList, getTokenBalances } from "@/views/app/walletlib";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import QrScanner from "qr-scanner";
import Vue from "vue";
import { addressEqual, chainEqual } from "@blockwell/chains";
export default {
    components: {
        AssetList,
        Heading,
        TransactionHistory,
        AddToken,
        ProgressCircle,
        ContractLoader,
        Modal,
        Clipboard,
    },
    data() {
        return {
            indicatorLeft: 0,
            activeTab: 0,
            showAddToken: false,
            assets: [],
            loadingAssets: false,
            assetErrors: [],
        };
    },
    computed: {
        account() {
            return this.$store.state.user.account;
        },
        api() {
            return this.$store.getters["user/api"];
        },
    },
    asyncComputed: {
        hasCamera() {
            return QrScanner.hasCamera();
        },
        app() {
            return randomBlockwellApp();
        },
    },
    watch: {
        account: {
            immediate: true,
            handler() {
                this.updateAssets();
            },
        },
    },
    methods: {
        etherscan(network) {
            let net = getNetwork(network);
            return net.explorerAddress(this.account);
        },
        openApp() {
            if (!/^https?:\/\//.test(this.app.url)) {
                this.$router.push(this.app.url);
            }
            else {
                window.location = this.app.url;
            }
        },
        move(change) {
            this.$refs.indicator.style.left = 50 * change + "%";
        },
        tabChange() {
            this.activeTab = this.$refs.tabs.$swiper.activeIndex;
        },
        slideTo(index) {
            this.$refs.tabs.$swiper.slideTo(index);
        },
        updateAssets() {
            let account = this.account;
            if (account) {
                this.loadingAssets = true;
                let assetErrors = Vue.observable([]);
                this.assetErrors = assetErrors;
                this.api
                    .tokenList()
                    .then((res) => {
                    let tokenList = res.concat(ethList);
                    let chains = getTokenBalances(tokenList, account, (network, err, list) => {
                        chains = chains.filter((it) => it !== network.chainId);
                        if (chains.length === 0) {
                            this.loadingAssets = false;
                        }
                        if (!err) {
                            if (list.length > 0) {
                                for (let token of list) {
                                    let index = this.assets.findIndex((it) => chainEqual(it.network, token.network) &&
                                        addressEqual(it.address, token.address));
                                    if (index > -1) {
                                        this.assets.splice(index, 1, token);
                                    }
                                    else {
                                        this.assets.push(token);
                                    }
                                }
                                let index = assetErrors.findIndex((it) => it.network.chainId === network.chainId);
                                if (index > -1) {
                                    assetErrors.splice(index, 1);
                                }
                                this.assets = this.assets.filter((asset) => {
                                    return (asset.network !== network.chainId ||
                                        list.find((it) => addressEqual(it.address, asset.address)));
                                });
                            }
                            else {
                                this.assets = this.assets.filter((asset) => {
                                    return asset.network !== network.chainId;
                                });
                            }
                        }
                        else {
                            let index = assetErrors.findIndex((it) => it.network.chainId === network.chainId);
                            let msg = {
                                network,
                                error: `There was an error loading balances for this network: ${err.message}`,
                            };
                            if (index > -1) {
                                assetErrors.splice(index, 1, msg);
                            }
                            else {
                                assetErrors.push(msg);
                            }
                        }
                    });
                    setTimeout(() => {
                        for (let chainId of chains) {
                            assetErrors.push({
                                network: getNetwork(chainId),
                                error: `Can't connect to this network to load token balances. Tokens on this network may be missing from the list below.`,
                            });
                        }
                        this.loadingAssets = false;
                    }, 10000);
                })
                    .catch((err) => {
                    console.error(err);
                    assetErrors.push({
                        error: "Unknown error loading token balances: " + err.message,
                    });
                });
            }
        },
        openAsset(asset) {
            this.$router.push(asset.sendLink);
        },
        assetOptions(event, asset) {
            event.preventDefault();
            this.optionsAsset = asset;
        },
        hideToken(asset) {
            this.api.hideToken(asset.network, asset.address).then(() => {
                this.updateAssets();
            });
        },
        addToken() {
            this.showAddToken = true;
        },
        tokenAdded() {
            this.showAddToken = false;
            this.updateAssets();
        },
    },
};
