import { getNetwork } from "@/assets/lib/networks";
import AssetLocks from "@/components/AssetLocks";
import Clipboard from "@/components/Clipboard";
import BigNumber from "bignumber.js";
export default {
    name: "Asset",
    components: { Clipboard, AssetLocks },
    props: {
        asset: {
            type: Object,
        },
        account: String,
        noHide: Boolean,
    },
    data() {
        return {
            dropdownOpen: false,
            lockOpen: false,
        };
    },
    computed: {
        ether() {
            return this.asset.address === "0x0000000000000000000000000000000000000000";
        },
        balance() {
            return this.asset.available || this.asset.balance || new BigNumber(0);
        },
        balanceAvailable() {
            return !this.balance.isNaN();
        },
        balanceWhole() {
            return this.balance.dp(0, BigNumber.ROUND_FLOOR).toFormat();
        },
        balanceDecimals() {
            let decimals = this.balance.mod(1);
            if (decimals.gt(0)) {
                return decimals.toString(10).slice(2);
            }
            return null;
        },
        usd() {
            if (this.asset.price?.gt(0)) {
                return this.asset.usd.toFormat(2);
            }
            if (this.asset.address.toLowerCase() === "0x555555555130790a502c3c95e9c26633c10ae000") {
                return new BigNumber(0.5).times(this.asset.balance || "0").toFormat(2);
            }
            return null;
        },
        network() {
            return getNetwork(this.asset.network);
        },
    },
    methods: {
        logoError(asset) {
            asset.logo = null;
        },
        newBalance(balance) {
            this.asset.available = balance;
        },
        newLocked(locked) {
            this.asset.locked = locked;
        },
        login() {
            this.$bus.emit("login_request", {
                message: "",
            });
        },
    },
};
