<template>
    <layout-default>

        <div class="row align-items-start">
            <div class="col-md-2"></div>
            <div class="col-md-7 wallet-send">
                <div class="back">
                    <router-link to="/app/wallet"><i class="material-icons">chevron_left</i> Back to Wallet
                    </router-link>
                </div>
                <div class="card">
                    <div class="card-title">
                        <h3>Send Tokens</h3>
                    </div>
                    <div class="card-content">
                        <form action="#" method="post" class="token-send-form">
                            <div class="form-group row no-gutters">
                                <label class="col-12">Token Contract</label>
                                <div class="col-md-12">
                                    <select name="token" class="custom-select token-select">

                                    </select>
                                </div>
                                <div class="invalid-feedback token-contract-feedback"></div>
                                <small class="form-text text-muted col-12">
                                    Select the token to transfer.
                                </small>
                            </div>

                            <div class="form-group row no-gutters">
                                <label class="col-12 to-account-label">
                                    To Account
                                    <div class="to-account-selector">
                                        <span id="email" :class="{active: !showWallet}" @click="showWallet = false">email</span>
                                        <span :class="{active: showWallet}" @click="showWallet = true">wallet</span>
                                    </div>
                                </label>
                                <div class="col-md-10">
                                    <input v-show="!showWallet" type="email" name="to_email" value="" class="form-control"
                                           id="to-email" />

                                    <FormulateForm v-model="formValues">
                                    <AddressInput
                                        v-show="showWallet"
                                        id="to-account"
                                        :field="{name: 'to', label: 'To'}"
                                        @updated="walletUpdate"
                                    />
                                    </FormulateForm>
                                </div>
                                <div class="col-md-10">
                                </div>
                                <small class="form-text text-muted col-12">
                                    Enter the wallet or email address to send tokens to.
                                </small>
                            </div>

                            <div class="form-group row no-gutters">
                                <label class="col-12">Token Amount</label>
                                <div class="col-md-6">
                                    <input name="amount" step="any" value="" id="amount"
                                           inputmode="numeric"
                                           pattern="[0-9 \.]+"
                                           v-cleave="{
                                            numeral: true,
                                            numeralThousandsGroupStyle: 'thousand',
                                            delimiter: ' '
                                           }"
                                           class="form-control" />
                                </div>
                                <small class="form-text text-muted col-12">
                                    Enter the amount of tokens to send.
                                </small>
                                <div class="balance-wrap">
                                    Current Balance: <span class="token-balance"></span>
                                </div>
                            </div>

                            <div class="form-error error"></div>

                            <button id="submit" class="btn btn-primary" type="submit">Send</button>
                        </form>
                    </div>
                </div>
                <div class="card-panel current-transfers steps submitted-steps reverse-steps">
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script>
import AddressInput from "@/components/dumbapp/input/AddressInput";
import { getTokenBalances, getTokenData } from "@/lib/eth/Token";
import * as filter from "secure-filters";
import * as util from "@/assets/lib/util";
import Api from "@/lib/api/WalletApi";
import * as moment from "moment";
import Dapp from "@/assets/lib/Dapp";
import { toChainId, getNetwork } from "@/assets/lib/networks";

const api = new Api("legacy");

let tokens = [];
let currentToken;
let currentAccount;
let toWallet;

export default {
    components: { AddressInput },
    data() {
        return {
            showWallet: false,
            formValues: {
                to: ""
            }
        }
    },
    methods: {
        walletUpdate(val) {
            toWallet = val.val;
            this.formValues.to = val.val;
            $('#to-account').val(val.val);
        }
    },
    watch: {
        'formValues.to'(val) {
            toWallet = val;
        }
    },
    mounted() {

        api.getAccounts()
            .then(async accounts => {
                currentAccount = accounts[0].address;
                await loadTokenList(currentAccount);

                let urlParams = new URLSearchParams(window.location.search);
                let urlContractAddress = urlParams.get("contract");
                let urlNetwork = toChainId(urlParams.get("net"));

                if (urlContractAddress && urlNetwork) {
                    await loadToken(urlNetwork, urlContractAddress, currentAccount);
                } else {
                    // Food Coin by default
                    await loadToken(5, "0xFf255f8A7Cf00D68a123a2553a7d0cdCA63f61c3", currentAccount);
                }

                $(".token-select").val(`${currentToken.network}-${currentToken.address.toLowerCase()}`);
            })
            .catch(err => {
                $(".token-contract-feedback").text("Failed to load token: " + util.errorText(err)).show();
                console.error(err);
            });

        $(".token-send-form").submit(function(ev) {
            ev.preventDefault();

            submit(this)
                .catch(console.error);
        });

        $(".token-select").change(function() {
            // Make sure this is cleared first, so we don't get into a situation where a different token is
            // accidentally used
            currentToken = null;
            let [network, address] = $(this).val().split("-");
            loadToken(parseInt(network), address, currentAccount)
                .catch(err => {
                    $(".token-contract-feedback").text("Failed to load token: " + util.errorText(err)).show();
                    console.error(err);
                });
        });

        $('#amount').keydown(function (event) {
            if (event.code === "KeyK") {
                let cleave =  $('#amount')[0].cleave;
                let val = cleave.getRawValue();
                cleave.setRawValue(val * 1000);
            } else if (event.code === "KeyM") {
                let cleave =  $('#amount')[0].cleave;
                let val = cleave.getRawValue();
                cleave.setRawValue(val * 1000000);
            } else if (event.code === "KeyB") {
                let cleave =  $('#amount')[0].cleave;
                let val = cleave.getRawValue();
                cleave.setRawValue(val * 1000000000);
            }
        });
    }
};

async function loadTokenList(wallet) {
    let tokenList = await api.tokenList();
    tokens = (await util.getTokenBalances(tokenList, wallet)).flatMap(it => it.tokens);

    for (let token of tokens) {
        addToken(token);
    }
}

function addToken(token) {
    let net = getNetwork(token.network);

    $(".token-select").append(`
        <option value="${token.network}-${token.address.toLowerCase()}" selected="">${token.name} - ${net.networkName}</option>
    `);
}

async function loadToken(network, address, wallet) {

    let token = tokens.find(it => it.network === network && it.address === address);

    if (!token) {
        const data = (await getTokenData(network, [address]))[0];
        token = {
            network,
            address,
            name: data.name,
            symbol: data.symbol,
            decimals: data.decimals
        };
        tokens.push(token);
        addToken(token);
    }

    currentToken = token;

    await loadBalance(token, wallet);
}

/**
 *
 * @param {{}} token
 * @param {string} wallet
 * @return {Promise<void>}
 */
async function loadBalance(token, wallet) {
    let [balance] = await getTokenBalances(token.network, [token.address], wallet);

    $(".token-balance").html(util.renderAmount(balance, token.decimals, token.symbol));
}

async function updateBalance(token) {
    if (currentToken === token) {
        await loadBalance(token, currentAccount);
    }
}

async function submit(form) {
    if (!currentToken) {
        $(".form-error").empty().append("<p>Select a valid token.</p>");
        return;
    }

    let $submit = $("#submit");
    $submit.attr("disabled", true);

    let data = util.getFormData(form);

    let to;
    let email;
    if ($("#email").hasClass("active")) {
        to = data.to_email;
        email = to;
    } else {
        to = toWallet;
        data.to = toWallet;
    }

    data.amount = $("#amount")[0].cleave.getRawValue();

    try {
        let res = await api.transferDirect(currentToken.network, currentToken.address, to, util.toInteger(data.amount, currentToken.decimals));
        let tx = await api.getTransaction(res.transactionId);

        if (email) {
            tx.email = email;
        }

        renderTransaction(data, tx, currentToken);
        $("#amount").val("");
        $submit.attr("disabled", false);
    } catch (err) {
        console.error(err);
        $submit.attr("disabled", false);
        $(".form-error").empty().append("<p>Unknown error occurred, try again later.</p>");
    }
}

function renderTransaction(req, tx, token) {
    let id = tx.id;

    let addressLink = getNetwork(token.network).explorerToken(token.address);

    let $tx = $(`
<div class="step card">
    <h5 class="method-name">transfer</h5>
    <div class="contract-name">
        <span class="contract-name-label">Contract:</span>
        <div class="contract-name-content">
            <span class="contract-name-value">${token.name}</span>
            <span class="contract-name-address">
                (<a href="${addressLink}" target="_blank">${token.address}</a>)
                <img src="/images/clipboard.svg" width="11" class="clipboard account-clipboard"
                     data-clipboard-text="${token.address}" alt="Copy">
            </span>
        </div>
    </div>
    <div class="step-extras">
        <div class="status-wrap">
            <p class="status-line">Pending confirmation...</p>
            <div class="etherscan-wrap"></div>
        </div>
        <progress class="progress pure-material-progress-linear"></progress>
    </div>
    <div class="step-arguments">
        <div data-type="address">
            <label>To Wallet</label>
            <div class="send-argument-annotation">${tx.email}</div>
            <div class="value">${tx.parameters[0]}<img src="/images/clipboard.svg" width="11" class="clipboard account-clipboard"
                     data-clipboard-text="${tx.parameters[0]}" alt="Copy"></div>
        </div>
        <div data-type="uint">
            <label>Amount</label>
            <div class="value">${req.amount} ${token.symbol}</div>
        </div>
    </div>
</div>
`);

    $(".current-transfers").prepend($tx);

    let update = (data) => {
        if (data.transactionHash) {
            let url = getNetwork(token.network).explorerTx(data.transactionHash);
            $tx.find(".etherscan-wrap").html(`
                    <a href="${url}" target="_blank">Explorer Link</a>
                `);
        }

        switch (data.status) {
            case "completed":
                $tx.find(".progress").hide();
                $tx.find(".status-line").html(`
                    <i class="material-icons">check</i> Success
                `).addClass("confirmed");
                break;
            case "error":
                $tx.find(".status-line").text("Error").addClass("error");

                let message;
                if (data.error) {
                    if (data.error.code) {
                        message = data.error.message;
                        if (data.error.gasRequired) {
                            let amount = util.toDecimals(data.error.gasRequired, 18);
                            message += ` Send at least ${amount} ETH to your wallet and try again.`;
                        }
                    } else {
                        message = data.error.toString();
                    }
                }

                $tx.find(".step-extras").append(`<p class="error">${filter.html(message)}</p>`);
                $tx.find(".progress").hide();
                break;
        }
    };

    update(Object.assign(req, tx));

    let interval = setInterval(() => {
        return api.getTransaction(id)
            .then(response => {
                let newRes = response;
                update(Object.assign(req, newRes));
                if (newRes.ended) {
                    clearInterval(interval);
                    return updateBalance(token);
                }
            })
            .catch(err => {
                alert(err.message);
            });
    }, 3000);

    $tx.get(0).scrollIntoView({
        behavior: "smooth"
    });
}

</script>

<style>
.token-send-form div.dumbapp-address-input {
    margin-bottom: 0;
}
</style>
