<template>
    <div class="transaction-history" :id="$id()">
        <div class="control-bar">
            <Pagination :pages="pages" :page.sync="page"/>
            <div class="search-wrap">
                <input class="form-control" type="text" v-model="filterValue"
                       placeholder="Search"/>
                <a href="#" class="clear-filter" @click.prevent="clearFilter">Clear</a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-sortable">
                <thead>
                <tr>
                    <th @click="sort" data-field="transactionHash">Hash</th>
                    <th @click="sort" data-field="meta.created" class="down">Age</th>
                    <th @click="sort" data-field="contractName">Contract</th>
                    <th @click="sort" data-field="method">Type</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="it in list" :key="it.id" @click="$router.push(it.link)" class="history-row">
                    <td class="txhash">
                        <Clipboard v-if="it.transactionHash" :clipboard="it.transactionHash" :link="it.explorerLink" ellipsize/>
                        <span v-else-if="it.status === 'error'" class="error">Transaction Failed</span>
                    </td>
                    <td>
                        {{ it.time }}
                    </td>
                    <td>
                        {{ it.contractName }}
                        <router-link v-for="link in it.extraLinks"
                                     :key="link.name"
                                     :to="link.url" class="extra-link" @click.native.stop>{{ link.name }}</router-link>
                    </td>
                    <td class="method">
                        {{ it.displayType }}
                    </td>
                </tr>

                </tbody>
            </table>
        </div>

        <Pagination :pages="pages" :page.sync="page"/>
    </div>
</template>

<script>
import bwTypeMap from "@/assets/data/TypeMap";
import Clipboard from "@/components/Clipboard";
import Pagination from "@/components/Pagination";
import {debounce} from "@/lib/vutil";
import { getChain } from "@blockwell/chains";
import * as moment from "moment";
import {clone} from "rambdax";
import {mapGetters} from "vuex";

const weekAgo = moment().subtract(7, 'days');

export default {
    name: "TransactionHistory",
    components: {Clipboard, Pagination},
    data() {
        return {
            filterValue: "",
            filter: "",
            sorting: "-meta.created",
            pageSize: 50,
            page: 1,
            result: []
        };
    },
    computed: {
        ...mapGetters('user', ['api']),
        pages() {
            return Math.ceil(this.count / this.pageSize);
        },
        count() {
            return this.result?.count || 1;
        },
        list() {
            return this.result?.data?.map(it => {
                let m = moment(it.created);
                let time;
                if (m.isBefore(weekAgo)) {
                    time = m.format('MMM D, h:mm a');
                } else {
                    time = m.fromNow(true);
                }

                let type;

                if (it.type === 'contract-deploy') {
                    type = 'Contract Deploy';
                } else if (it.type === 'contract-method') {
                    type = it.method;
                } else {
                    type = 'Ether';
                }

                let link = `/app/wallet/transactions/${it.id}`;

                let extraLinks = [];
                if (it.contractBwtype) {
                    let bwtype = bwTypeMap[it.contractBwtype];
                    if (bwtype && bwtype.happ) {
                        extraLinks.push({
                            url: `/${bwtype.happ}?net=${it.contractNetwork}&contract=${it.contractAddress}`,
                            name: 'happ'
                        });
                    }
                }

                return {
                    ...it,
                    time,
                    displayType: type,
                    link,
                    extraLinks,
                    explorerLink: getChain(it.network).explorerTx(it.transactionHash)
                };
            });
        },
        query() {
            let q = {
                sort: this.sorting,
                pageSize: this.pageSize,
                text: this.filter,
                page: this.page
            };

            console.dir(clone(q));

            return q;
        }
    },
    watch: {
        filterValue: {
            handler: debounce(function () {
                this.filter = this.filterValue;
            })
        },
        query: {
            immediate: true,
            deep: true,
            handler(val) {
                this.api.getTransactions(val)
                    .then(result => {
                        this.result = result;
                    })
                    .catch(console.error);
            }
        }
    },
    methods: {
        clearFilter() {
            this.filter = "";
            this.filterValue = "";
        },
        sort(e) {
            let $ele = $(e.target);
            let sort = $ele.data('field');

            if ($ele.hasClass('down')) {
                $('th', this.$el).removeClass('up').removeClass('down');
                $ele.addClass('up');
                this.sorting = sort;
            } else {
                $('th', this.$el).removeClass('up').removeClass('down');
                $ele.addClass('down');
                this.sorting = '-' + sort;
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.transaction-history .table {
    line-height: 27px;

    ::v-deep( td ) {
        white-space: nowrap;
    }

    .txhash {
        ::v-deep( .ellipsize ) {
            max-width: 200px;

            @media screen and (max-width: 650px) {
                max-width: 150px;
            }
            @media screen and (max-width: 450px) {
                max-width: 100px;
            }
        }
    }

}


.control-bar {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;

    ::v-deep( .pagination-wrap ) {
        flex: 1 0 auto;
    }

    @media screen and (max-width: 420px) {
        font-size: 0.8em;
    }
}

.search-wrap {
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    .clear-filter {
        margin-left: 15px;
    }
}

.history-row {
    cursor: pointer;

    &:hover {
        background: rgba($dark, .05);
    }
}

.extra-link {
    margin-left: 10px;
}
</style>
