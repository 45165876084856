<template>
    <div class="asset-lock-list">
        Some of your tokens are locked and can't be used yet.
        <div v-if="!locks">
            <ProgressCircle small/>
        </div>
        <div v-else>
            <div v-for="lock in locks" class="asset-lock">
                <div class="lock-amount">
                    {{ lock.value.toFormat() }} {{ token.symbol }} Locked
                </div>
                <div class="lock-unlocks">
                    <template v-if="lock.partial">
                        <span class="partial-amount">{{ lock.partial.toFormat() }} {{ token.symbol }}</span>
                        will unlock on <span>{{ lock.expiration.format('lll') }}</span>, and then again every
                        <span>{{ lock.periodLength }}</span> until fully unlocked.
                    </template>
                    <template v-else>
                        Will unlock on <span>{{ lock.expiration.format('lll') }}.</span>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressCircle from "@/components/ProgressCircle";
import { Chain } from "@blockwell/chain-client";
import balanceAbi from "@/assets/data/BalanceToken.abi.json";
import {getTokenData} from "@/lib/eth/Token";
import {preciseDiff} from "@/lib/moment-precise-range";
import BigNumber from "bignumber.js";
import moment from "moment";
import {curryN} from "rambdax";

export default {
    name: "AssetLocks",
    components: {ProgressCircle},
    props: {
        asset: Object,
        account: String
    },
    data() {
        return {
            token: null,
            locks: null
        };
    },
    methods: {
        parseLocks(ints, withSplitting = true) {
            let locks = [];
            let i = 0;
            while (i < ints.length) {
                let lock = {
                    value: ints[i++].div(`1e${this.token.decimals}`),
                    expiration: moment.unix(ints[i++])
                };

                if (withSplitting) {
                    lock.periodLength = parseInt(ints[i++].toString(10));
                    lock.periods = parseInt(ints[i++].toString(10));
                }

                if (lock.periods < 2 && lock.expiration.isAfter()) {
                    locks.push(lock);
                } else if (lock.periods > 1) {
                    let diff = Math.max(Math.floor(Date.now() / 1000) - lock.expiration.unix(), 0);
                    let periods = Math.ceil(diff / lock.periodLength);

                    if (periods < lock.periods - 1) {
                        lock.partial = lock.value.div(lock.periods);
                        lock.value = lock.value.minus(lock.value.div(lock.periods).times(periods));
                        lock.expiration.add(lock.periodLength * periods, 'seconds');
                        lock.periods -= periods;
                        lock.periodLength = preciseDiff(moment(), moment().add(lock.periodLength, 'seconds'));

                        locks.push(lock);
                    } else if (periods < lock.periods) {
                        // The remaining value will all unlock next, so no more repeating
                        lock.periods = null;
                        locks.push(lock);
                    }
                }
            }

            let lockedTotal = locks.reduce((acc, val) => acc.plus(val.value), new BigNumber(0));

            this.$emit('locked', lockedTotal);

            this.locks = locks;
        }
    },
    created() {
        let asset = this.asset;
        getTokenData(asset.network, asset.address)
            .then(async token => {
                this.token = token[0];

                const read = Chain.curryRead(asset.network, asset.address, balanceAbi);

                let balance = new BigNumber(await read('availableBalanceOf', [
                    this.account
                ])).div(`1e${this.token.decimals}`);
                let locks = (await read('locksOf', [
                    this.account
                ], 3000)).map(it => new BigNumber(it));

                if (locks.length === 0) {
                    this.locks = [];
                } else if (locks.length === 2) {
                    this.parseLocks(locks, false);
                } else {
                    // If the fourth element is higher than this, it's a timestamp, and the lock format is old
                    if (locks[3].gt(65535)) {
                        this.parseLocks(locks, false);
                    } else {
                        this.parseLocks(locks);
                    }
                }

                this.$emit('balance', balance);
            })
            .catch(console.error);
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.asset-lock-list {
    padding: 10px;
    max-width: 320px;
}

.asset-lock {
    margin-top: 15px;
}

.lock-amount {
    font-weight: bold;
    font-size: 1.1em;
}

.lock-unlocks {
    line-height: 1.2;

    ::v-deep( span ) {
        font-weight: 600;
    }
}
</style>
