<template>
    <div class="asset-list">
        <!--        <FuelToken-->
        <!--            :account="account"-->
        <!--            />-->
        <Asset
            v-for="asset in sortedAssets"
            :asset="asset"
            :account="account"
            :key="asset.network + '-' + asset.address"
            @click="openAsset(asset)"
            @hide-token="hideToken(asset)"
            @contextmenu="assetOptions($event, asset)"
        />
        <Modal :show.sync="showAssetOptions" modal-class="asset-options-modal">
            <template #header>
                <h5>{{ optionsAsset.name }}</h5>
            </template>
            <div class="asset-options">
                <Clipboard
                    :clipboard="optionsAsset.address"
                    text="Copy Address"
                    link-only
                    class="dropdown-item"
                />
                <a
                    v-if="optionsAsset.address !== '0x0000000000000000000000000000000000000000'"
                    :href="optionsAsset.link"
                    @click="showAssetOptions = false"
                    target="_blank"
                    class="dropdown-item"
                >
                    View On Etherscan
                </a>
                <a
                    v-if="optionsAsset.address !== '0x0000000000000000000000000000000000000000'"
                    @click="hideToken(optionsAsset)"
                    href="javascript: void 0;"
                    class="dropdown-item error"
                >
                    Hide From List
                </a>
            </div>
        </Modal>
    </div>
</template>

<script>
import Asset from "@/components/Asset";
import Clipboard from "@/components/Clipboard";
import FuelToken from "@/components/fuel/FuelToken.vue";
import Modal from "@/components/Modal";
import { getPrices } from "@/lib/prices";
import { sortTokens } from "@/views/app/walletlib";
import { clone } from "rambdax";

export default {
    name: "AssetList",
    components: { FuelToken, Clipboard, Asset, Modal },
    props: {
        assets: Array,
        account: String,
    },
    data() {
        return {
            showAssetOptions: false,
            optionsAsset: {},
        };
    },
    computed: {
        sortedAssets() {
            return sortTokens(this.assets);
        },
    },
    methods: {
        openAsset(asset) {
            this.$router.push(asset.sendLink);
        },
        assetOptions(event, asset) {
            event.preventDefault();
            this.optionsAsset = asset;
            this.showAssetOptions = true;
        },
        hideToken(asset) {
            console.log("hideToken", asset);
            this.$emit("hide-token", asset);
            this.showAssetOptions = false;
        },
    },
};
</script>

<style scoped lang="scss">
.asset-options {
    font-size: 16px;
}
</style>
