<template>
    <article class="wallet-asset">
        <div class="wallet-asset-inner">
            <div class="asset-logo">
                <img src="../../assets/images/icons/fire.svg" alt="">
            </div>
            <div class="asset-info">
                <div class="asset-name">
                    {{ asset.name }}
                </div>
                <div class="asset-symbol">
                    {{ asset.symbol }}
                </div>

                <router-link
                    @click.native.stop
                    to="/fuel"
                >What Is Fuel?</router-link>
            </div>
            <div class="asset-data-wrap">
                <div class="asset-data">
                    <div class="asset-balance">
                        <span class="whole">{{ balanceWhole }}</span><span v-if="balanceDecimals"
                                                                           class="period">.</span><span
                        v-if="balanceDecimals" class="decimals">{{ balanceDecimals }}</span>
                    </div>
                    <div class="asset-usd">Buy Fuel</div>
                    <template v-if="asset.locked">
                        <v-popover :open.sync="lockOpen" trigger="manual">

                            <div class="asset-locked" @click.stop="lockOpen = true">
                                <i class="material-icons">lock</i>
                                {{ asset.locked.toFormat() }}
                            </div>
                            <template #popover>
                                <AssetLocks v-if="lockOpen" :asset="asset" :account="account" @balance="newBalance"
                                            @locked="newLocked"/>
                            </template>
                        </v-popover>
                    </template>
                </div>
            </div>
            <div v-if="!$screen.mobile" class="asset-options-dropdown">
            </div>
        </div>
    </article>
</template>

<script>
import {getNetwork} from "@/assets/lib/networks";
import AssetLocks from "@/components/AssetLocks.vue";
import Clipboard from "@/components/Clipboard.vue";
import {fuelTokenAddress, fuelTokenNetwork, loadFuel} from "@/lib/fuel";
import BigNumber from "bignumber.js";

export default {
    name: "FuelToken",
    components: {Clipboard, AssetLocks},
    props: {
        account: String
    },
    data() {
        return {
            lockOpen: false,
            asset: {
                address: fuelTokenAddress,
                network: fuelTokenNetwork,
                balance: new BigNumber(0),
                stake: new BigNumber(0)
            }
        };
    },
    computed: {
        balanceWhole() {
            return this.asset.stake.dp(0, BigNumber.ROUND_FLOOR).toFormat();
        },
        balanceDecimals() {
            let decimals = this.asset.stake.mod(1);
            if (decimals.gt(0)) {
                return decimals.toString(10).slice(2);
            }
            return null;
        }
    },
    methods: {
        loadData() {
            loadFuel(this.account)
                .then(data => {
                    let locked;
                    if (data.available && data.available.lt(data.balance)) {
                        locked = data.balance.minus(data.available);
                    } else {
                        locked = null;
                    }

                    this.asset = {
                        address: this.asset.address,
                        network: this.asset.network,
                        ...data,
                        locked
                    }
                })
                .catch(console.error);
        },
        newBalance(balance) {
            this.asset.available = balance;
        },
        newLocked(locked) {
            this.asset.locked = locked;
        }
    },
    created() {
        this.loadData();
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom";

</style>
